import React from "react";
import { Container, Grid, Divider, Image } from "semantic-ui-react";
import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import FeatureList from "../../components/feature-list/feature-list";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import BodyContent from "../../components/body-content/body-content";
import Quote from "../../components/quote/quote";
import colors from "../../components/colors";

const Page = () => (
  <LayoutInner microsite>
    <SEO title="Feel Good Friday – Free Music Resource for Primary Schools"
         keywords={[`rocksteady`]}
         description="Feel Good Friday is a free music curriculum resource for primary schools. Feel Good Friday includes musical games, live performances and in-class resources."
    />

    <Container>
      <SecondaryNavBar
        product="primary"
        title="Feel Good Friday"
        titlePath="/feelgoodfriday/"
        titleActive={true}
        links={[]}
        cta="Sign up"
        ctaLink="https://portal.rocksteadymusicschool.com/signup"
      />
      <HeadingOverImage
        text="FREE WEEKLY STREAMED MUSIC LESSONS FOR YOUR WHOLE SCHOOL"
        img="/images/feelgood-friday/bg.png"
        siteSection="primary"
      />

      <Divider hidden />

      <BodyContent>
        <h2
          style={{ color: "#0072CE", textTransform: "uppercase", fontSize: 30 }}
        >
          Feel Good Friday Music Streams
        </h2>
      </BodyContent>

      <BodyContent>
        <p style={{ fontSize: 18 }}>
          To help teachers deliver the wellbeing benefits of music to their
          pupils, we created 
          <strong>
            <span style={{ color: "#0072CE" }}>
              Feel Good Friday, fun 30-minute, whole class, weekly streamed music lessons for YR-2, Y3-4 and Y5-6.
            </span>{" "}
            Hundreds of schools join us every week.
          </strong>{" "}
          Lessons are completely free for schools and{" "}
          <strong>
            <span style={{ color: "#0072CE" }}>
            can be enjoyed on-demand
            </span>
          </strong>{" "}
          to fit in with your timetable.
        </p>
      </BodyContent>

      <FeatureList
        siteSection="sing"
        features={[
          {
            title: "Follows the music curriculum",
            text: <p>
              Children explore the music curriculum in new and exciting ways.
              For example they’ll learn all about different music genres, chords, rhythm, and tempo.
              {' '}
              <b>Find out what your pupils will learn this half-term by signing up to</b>
              {' '}
              <a href="https://portal.rocksteadymusicschool.com/signup">Schools Portal</a>.
            </p>,
          },
          {
            title: "Gives your pupils a wellbeing boost",
            text:
              "The lessons aim to give your pupils a weekly boost in wellbeing and confidence, which will help support them at school.",
          },
          {
            title: "No prep, planning or instruments needed",
            text:
              "The lessons aim to support you by giving you a valuable educational resource at the click of a button each week.",
          },
          {
            title: "Flexible to suit your needs",
            text:
              "Use it as your weekly class music lesson, build on it with follow up work or simply enjoy the interactive sessions.",
          },
          {
            title: "Watch on-demand",
            text:
              "Join in at a time or day that suits you, every week or once a month, it’s completely up to you.",
          },
          {
            title: "A highlight of the week for hundreds of schools",
            text:
              "It's a fun addition to the week and you can even request a shout out for your class in one of our seasonal special streams.",
          },
          {
            title: "Musical games, live performances and more",
            text:
              "Fun, new and innovative games to engage your pupils and help them develop a love of music.",
          },
          {
            title: "Tailored streams for YR-2, Y3-4 and Y5-6.",
            text:
              "Interactive and engaging lessons, designed carefully for each age group.",
          },
        ]}
      />

      <BodyContent>
        <p style={{ fontSize: 18, marginBottom: 30 }}>
          To get access to Feel Good Friday lessons for your school, click the
          link below. All you'll need is a valid official Primary School email
          address for the weekly Feel Good Friday links to be sent to.
        </p>

        <Grid verticalAlign="middle" stackable>
          <Grid.Row columns={2}>
            <Grid.Column textAlign="center">
              <a
                href="https://portal.rocksteadymusicschool.com/signup"
                className="ui button"
                style={{
                  backgroundColor: colors["primary"].cta,
                  color: colors["primary"].ctaText,
                  borderRadius: 30,
                  fontSize: "2em",
                  lineHeight: "initial",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to sign up to <br /> Feel Good Friday
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Image
                size="large"
                src="/images/feelgood-friday/paul_ford.png"
                centered
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid padded stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Quote
                siteSection="primary"
                quote="We join in every Friday and love your games
                  and songs. You pitch it all perfectly, well done
                  for making music so accessible for us."
                quoteName="Helen, Teacher, Sacred Heart Primary School"
                reducedMargin
              />
            </Grid.Column>
            <Grid.Column>
              <Quote
                siteSection="primary"
                quote="I am really impressed at all the musical terminology and skills that are being taught in every half hour session. It’s the first thing they ask me about on a Friday morning."
                quoteName="Emma, Teacher, Lockwood Primary School"
                reducedMargin
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </BodyContent>
    </Container>
  </LayoutInner>
);

export default Page;
